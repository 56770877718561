<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12">
        <v-card text class="mb-7" v-if="examFetch">
          <v-card-text class="py-4 px-6">
            <v-row>
              <v-col
                cols="12"
                align="right"
                class="d-flex justify-space-between tw-flex-col md:tw-flex-row"
                >
                <div class="text-center tw-flex-row tw-flex">
                  <h1 class="mb-0 text-h5 md:tw-mr-4" v-if="exam.data.name">{{ exam.data.name }}</h1>
                </div>
                <div class="tw-flex justify-space-between tw-flex-row md:mt-2">
                  <v-menu rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn color="gray" v-bind="attrs" v-on="on" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item  @click="$router.push({path: `/exam/application/${exam.metadata.id}`})">
                          <v-list-item-icon>
                            <v-icon>mdi-text-box-plus-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Aplicar avaliação</v-list-item-title>
                      </v-list-item>
                      <v-list-item :to="`/exam/edit/${this.exam.metadata.id}`">
                        <v-list-item-icon>
                          <v-icon>mdi-circle-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Editar avaliação</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="level >= 5" @click="exportExam(exam.metadata.id)">
                        <v-list-item-icon>
                          <v-icon>mdi-file-export-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Salvar em modelos de avaliação</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="dialogDelete=true">
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Excluir avaliação</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-skeleton-loader v-else v-bind="attrs" type="actions" class></v-skeleton-loader>

        <base-card v-if="examFetch" class="mt-5" heading="Questões">
          <v-row>
            <v-col class='d-flex justify-center align-center my-4'>
              <v-progress-circular
                :size="70"
                :width="7"
                :value="30"
                indeterminate
                color="primary"
                v-if="loadingQuestions"
              ></v-progress-circular>
              {{ message }}
            </v-col>
          </v-row>
          <tabs-question
            :toolbar="false"
            :questions="questions"
            :index="currentPage"
            :elevation="0"
            class="mb-4 mt-2"
          >
            <v-pagination
              v-if="questions.length"
              depressed
              :light="true"
              v-model="currentPage"
              class="my-4"
              :length="pagination.last_page"
            ></v-pagination>
          </tabs-question>

          <dialog-base title="Importar Questão" :show="importDialog" @close="importDialog = false">
            <list-question :selection="true" />
          </dialog-base>
        </base-card>
        <v-skeleton-loader
          v-else
          v-bind="attrs"
          type="article, actions,article, actions, article"
          class="my-5"
        ></v-skeleton-loader>

        <base-card
          v-if="applications.items && applications.items.length"
          class="mt-5"
          heading="Aplicações em andamento"
        >
          <v-slide-group v-model="model" class="pt-4" :show-arrows="!$vuetify.breakpoint.mobile">
            <v-slide-item v-for="app in applications.items" :key="app.metadata.id">
              <v-card
                class="ml-4 mb-4 tw-min-w-[280px]"
                width="280"
                outlined
                :to="`/exam/application/view/${exam.metadata.id}/${app.metadata.id}`"
              >
                <div class="pa-4">
                  <v-chip
                    small
                    class
                    dark
                    label
                    v-if="app.data.status !== 'DRAFT'"
                    :color="getStatusColor(app.data.status)">
                    {{
                      getStatusName(app.data.status)
                    }}
                  </v-chip>
                  <p class="title tw-text-2xl mb-2 mt-2">{{ app.data.name }}</p>
                  <span class="tw-font-medium tw-text-gray-700">Resumo</span>
                  <ul class="details tw-text-sm tw-block text-truncate">
                    <li class="d-flex mb-1">
                      Início: {{ format(parseISO(app.data.starts_at),"dd'/'MM'/'yyyy', às ' HH:mm'h'", {locale: pt}) }}
                    </li>

                    <li class="d-flex mb-1">
                      Término: {{ format(parseISO(app.data.ends_at),"dd'/'MM'/'yyyy', às ' HH:mm'h'", {locale: pt}) }}
                    </li>
                    <li class="d-flex mb-1">{{ stripHtml(app.data.instructions).trim() }}</li>
                  </ul>
                </div>

                <p class="tw-font-medium ml-4 mb-0 tw-min-h-[1rem] tw-text-gray-700">
                  <span v-if="app.data.courses_group.length">Turmas</span>
                </p>
                <v-card-actions
                  class="tw-overflow-x-scroll md:tw-overflow-hidden mx-1 py-0 tw-min-h-[2.5rem]"
                >
                  <div
                    v-for="(item, index) in (app.data.courses_group.length<=4) ? app.data.courses_group : app.data.courses_group.slice(1,5)"
                    :key="item.id"
                    :class="{ 'tw--ml-3': index > 0 }"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          size="36"
                          class="tw-border-2 tw-border-white"
                          style="box-shadow: -1px 1px 4px 0px rgb(0 0 0 / 20%);"
                        >
                          <v-img
                            :src="`https://gravatar.com/avatar/${md5(
                                  item.id||'avatar'
                                )}?s=80&d=identicon&r=x`"
                            v-on="on"
                            v-bind="attrs"
                            :alt="item.name"
                          />
                        </v-avatar>
                      </template>
                      <span>
                        {{ item.name }} {{ item.grade }} - Sala {{ item.room }}
                      </span>
                    </v-tooltip>
                    <v-avatar
                      v-if="index===3 && (app.data.courses_group.length>4)"
                      size="36"
                      class="tw--ml-3 tw-border-2 tw-border-white tw-bg-white"
                      style="box-shadow: -1px 1px 4px 0px rgb(0 0 0 / 20%); font-size: 12px; font-weight: 600;"
                    >
                      <span>+</span>
                      <span>{{ app.data.courses_group.length-4 }}</span>
                    </v-avatar>
                  </div>
                </v-card-actions>
                <div class="mx-1">
                  <v-card-actions>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="24">
                          <img v-on="on" v-bind="attrs" :src="app.data.author.avatar" />
                        </v-avatar>
                      </template>
                      <span>{{ app.data.author.name }}</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="ml-5"
                      color="primary"
                      elevation="0"
                      text
                      :to="`/exam/application/view/${exam.metadata.id}/${app.metadata.id}`"
                    >Ver mais</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="ml-5"
              color="primary"
              text
              :to="`/applications/${exam.metadata.id}`"
            >Ver todos</v-btn>
          </v-card-actions>
        </base-card>

        <v-skeleton-loader
          v-if="!applicationsFetch"
          v-bind="attrs"
          type="article, actions"
          class="my-5"
        ></v-skeleton-loader>

        <v-card v-if="applicationsFinished.items && applicationsFinished.items.length" class="mt-5">
          <v-card-text>
            <v-row>
              <h1 class="text-h5 px-4 py-4">Aplicações finalizadas</h1>
            </v-row>
            <v-row>
              <div class="d-flex flex-nowrap tw-overflow-x-scroll px-4">
                <v-card
                  v-for="app in applicationsFinished.items"
                  :key="app.metadata.id"
                  class="mr-4 mb-4 tw-min-w-[300px]"
                  width="300"
                  outlined
                  :to="`/exam/application/view/${exam.metadata.id}/${app.metadata.id}`"
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text">Aplicada para</div>
                      <div class="d-flex tw-items-center tw-text-gray-700">
                        <v-icon color="gray" size="40">mdi-calendar-clock</v-icon>
                        <div class="tw-flex tw-content-between ml-2">
                          <div class="tw-text-[3.5rem]">
                            {{
                            format(parseISO(app.data.starts_at), "dd", {
                            locale: pt,
                            }) || ""
                            }}
                          </div>
                          <div class="tw-mt-[.5rem] ml-1">
                            <div class="tw-text-[1.3rem] tw-uppercase">
                              {{
                              format(parseISO(app.data.starts_at), "MMM", {
                              locale: pt,
                              }) || ""
                              }}
                            </div>
                            <div class="tw-text-[1.2rem]">
                              {{
                              format(parseISO(app.data.starts_at), "HH:mm", {
                              locale: pt,
                              }) || ""
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="tw-text-2xl mb-1 tw-block text-truncate">{{ app.data.name }}</p>
                      <v-list-item-subtitle
                        class="tw-text-sm mb-1 tw-block text-truncate"
                      >{{ stripHtml(app.data.instructions) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions
                    class="tw-overflow-x-scroll md:tw-overflow-hidden mx-1 py-0 tw-min-h-[2.5rem]"
                  >
                    <div
                      v-for="(item, index) in app.data.courses_group"
                      :key="item.id"
                      :class="{ 'tw--ml-2': index > 0 }"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar size="36">
                            <v-img
                              :src="`https://gravatar.com/avatar/${md5(
                                item.id
                              )}?s=80&d=identicon&r=x`"
                              v-on="on"
                              v-bind="attrs"
                              :alt="item.name"
                            />
                          </v-avatar>
                        </template>
                        <span>
                          {{ item.name }} {{ item.grade }}
                          {{ item.room }}
                        </span>
                      </v-tooltip>
                    </div>
                  </v-card-actions>
                  <div class="mx-1">
                    <v-card-actions>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar size="36">
                            <img v-on="on" v-bind="attrs" :src="app.data.author.avatar" />
                          </v-avatar>
                        </template>
                        <span>{{ app.data.author.name }}</span>
                      </v-tooltip>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ml-5"
                        color="primary"
                        text
                        :to="`/exam/application/view/${exam.metadata.id}/${app.metadata.id}`"
                      >Ver mais</v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="ml-5"
              color="primary"
              text
              :to="`/applications/${exam.metadata.id}`"
            >Ver todos</v-btn>
          </v-card-actions>
        </v-card>
        <v-skeleton-loader
          v-if="!applicationsFinishedFetch"
          v-bind="attrs"
          type="article, actions"
          class="my-5"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <dialog-delete
        :dialog="dialogDelete"
        header-message="Deseja excluir essa avaliação?"
        body-message="Essa ação não pode ser desfeita depois da confirmação."
        deny-message="Cancelar"
        confirm-message="Confirmar exclusão"
        :delete-endpoint="`/organizations/${id}/exams/${exam.metadata.id}`"
        @deleteSuccess="deleteSuccess()"
        @closeModal="closeModal()" />
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import TabsQuestion from '../../components/list/TabsQuestion.vue'
import md5 from '@/libs/md5'
import { getStatusName, getStatusColor } from '@/utils/status'

import {
  parseISO,
  format,
  addDays,
  formatRelative,
  formatDistance
} from 'date-fns'

import pt from 'date-fns/locale/pt'

export default {
  components: { TabsQuestion },
  data: () => ({
    pt,
    format,
    addDays,
    formatRelative,
    formatDistance,
    parseISO,
    id: '',
    exam: {},
    loadingQuestions: false,
    show: false,
    examFetch: false,
    applicationsFetch: false,
    applicationsFinishedFetch: false,
    applications: [],
    applicationsFinished: [],
    questions: [],
    questions_pagination: '',
    questionsFetch: false,
    importDialog: false,
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2
    },
    pagination: {
      current_page: 1,
      per_page: 10,
      prev_page_url: false,
      next_page_url: false,
      total: 10
    },
    currentPage: 1,
    message: '',
    dialogDelete: false
  }),
  watch: {
    currentPage (page) {
      this.pagination.current_page = page
      this.getQuestions()
    }
  },
  computed: {
    ...mapState(['user']),
    level () {
      return this.user.level
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    md5,
    stripHtml (text) {
      if (!text) return ''
      return text
        .replace(/<[^>]*>?/gm, '')
        .replace('\\(', '')
        .replace('\\)', '')
        .replace(/&nbsp;/g, '')
    },
    exportExam (idExam) {
      this.setLoading(true)
      const self = this
      this.$axios
        .post(`/organizations/${this.id}/exams/${idExam}/export`)
        .then(response => {
          self.$store.dispatch('alert', {
            msg: 'Avaliação exportada com sucesso'
          })
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    getExam () {
      const msg = 'Erro na conexão, tente novamente'
      this.$axios.get(
          `organizations/${this.id}/exams/${this.$route.params.id}`
      ).then(res => {
        this.exam = res.data
      }).catch(() => {
        this.$store.dispatch('alert', { color: 'red', msg })
      }).finally(() => {
        this.examFetch = true
      })
      this.$axios.get(
          `/organizations/${this.id}/exams/${this.$route.params.id}/applications`
      ).then(res => {
        this.applications = {
          ...res.data
        }
      }).catch(() => {
        this.$store.dispatch('alert', { color: 'red', msg })
      }).finally(() => {
        this.applicationsFetch = true
        this.applicationsFinishedFetch = true
      })
    },
    getQuestions () {
      const msg = 'Erro na conexão, tente novamente'
      this.loadingQuestions = true
      const pagination = `?page=${this.pagination.current_page}&per_page=${this.pagination.per_page}`
      this.questions = []
      this.$axios.get(
          `organizations/${this.id}/exams/${this.$route.params.id}/questions${pagination}&with_wordings=1`
      ).then(res => {
        if (res.data.items.length < 1) this.message = 'Nenhuma questão encontrada'
        this.pagination = res.data.pagination
        this.questions = res.data.items
        this.questions_pagination = this.questions.length > 0 ? 1 : this.questions.length.toString()[0] + 1
      }).catch(() => {
        this.$store.dispatch('alert', { color: 'red', msg })
      }).finally(() => {
        this.questionsFetch = true
        this.loadingQuestions = false
        this.setLoading(false)
      })
    },
    handleClick () {
      this.$router.push({ path: '/class' })
    },
    getStatusName,
    getStatusColor,
    deleteSuccess () {
      this.dialogDelete = false
      this.$router.push({
        path: '/exam/list',
        replace: true
      })
      this.$store.dispatch('alert', {
        msg: 'Avaliação excluída com sucesso.'
      })
    },
    closeModal () {
      this.dialogDelete = false
    }
  },
  mounted () {
    this.setLoading(true)
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (this.$route.params.id) {
      this.getExam()
      this.getQuestions()
    } else {
      this.$router.push({ path: '/exam/list' })
    }
  }
}
</script>
<style scoped>
.title {
  min-height: 64px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
ul.details li::before {
  content: "\2022";
  color: rgb(0, 110, 255);
  font-weight: bold;
  display: inline-block;
  width: 16px;
  margin-left: -20px;
  font-size: 25px;
}
</style>
